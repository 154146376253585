import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { StyleSheet, css } from 'aphrodite';

import { ImageUploadIllustration, CloseIcon } from 'utilities/icons';
import { hexToRGBA } from 'utilities';
class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            fileURLs: [],
            errorMessage: null,
            userDragging: false,
            submitWait: false,
        };
        this.dropArea = React.createRef();
    }
    componentDidMount () {
        if (!this.dropArea.current) { return }
        this.dropArea.current.addEventListener('dragover', this.handleDragOver);
        this.dropArea.current.addEventListener('drop', this.handleDrop);
        this.dropArea.current.addEventListener('dragenter', this.handleDragEnter);
        this.dropArea.current.addEventListener('dragleave', this.handleDragLeave);
        this.dropArea.current.addEventListener('click', this.handleClick);
    }
    componentWillUnmount () {
        this.dropArea.current.removeEventListener('dragover', this.handleDragOver);
        this.dropArea.current.removeEventListener('drop', this.handleDrop);
        this.dropArea.current.removeEventListener('dragenter', this.handleDragEnter);
        this.dropArea.current.removeEventListener('dragleave', this.handleDragLeave);
        this.dropArea.current.removeEventListener('click', this.handleClick);
    }
    handleClick = () => {
        const { fileURLs } = this.state;
        const inputElement = document.getElementById('fileSelector');
        // set onchange event to call callback when user has selected file
        inputElement.addEventListener("change", () => {
            // there's a weird glitch where this event listener will fire like 3 times.
            // only call setFiles if there are files and they are different from the files in state
            const inputElementFiles = [...inputElement.files];
            const shouldSetFiles = inputElementFiles.length !== this.state.files.length;
            shouldSetFiles && inputElementFiles && this.setFiles(inputElementFiles);
        })
        // dispatch a click event to open the file dialog
        fileURLs.length === 0 && inputElement.dispatchEvent(new MouseEvent("click")); 
    }
    handleDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({userDragging: true});
    }
    handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({userDragging: false});
    }
    // delete below if not doing anything
    handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    }
    handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({userDragging: false});
        const files = [...e.dataTransfer.files];
        this.setFiles(files);
    }
    setFiles = async (files) => {
        const { onUpload, requiredImageHeight, requiredImageWidth} = this.props;
        if (!files || !files.length) return;
        const uploadCheck = await this.checkUploads(files);
        if (uploadCheck) {
            onUpload && onUpload(files);
            this.setState({
                files: [...this.state.files, ...files],
                fileURLs: [...this.state.fileURLs, ...files.map(file => URL.createObjectURL(file))], 
                errorMessage: null
            }, () => {
                (requiredImageHeight || requiredImageWidth) && this.checkImageDimensions();
            });
        }
    }
    checkUploads = (files) => {
        const { count, formats, maxFileSize } = this.props;
        // check count of files
        if (count && count < files.length + this.state.files.length) {
            this.setState({errorMessage: 'count'});
            return false;
        }
        // check formats
        if (formats && files.some((file) => !formats.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase())))) {
            this.setState({errorMessage: 'formats'});
            return false;
        }
        // check file size
        if (files && files.filter(file => file.size > maxFileSize).length) {
            this.setState({errorMessage: 'size'});
            return false;
        }
        return true;
    }
    getImageDimensions = async (image) => {
        let img = new Image();
        img.src = await window.URL.createObjectURL(image);
        img.onload = () => {
            return {width: image.width, height: image.height};
        }
    }
    // check image dimensions func SHOULD be inside of checkUploads func, however I couldn't figure it out
    checkImageDimensions = () => {
        const { requiredImageWidth, requiredImageHeight } = this.props;
        let removeIndexes = [];
        const files = [...document.querySelectorAll('.imageUploadFile')];
        files && files.forEach((file, i) => {
            let img = new Image();
            img.src = file.src;
            img.onload = () => {
                const dimensions = {width: img.width, height: img.height};
                if (
                    (requiredImageHeight && requiredImageHeight !== dimensions.height) ||
                    (requiredImageWidth && requiredImageWidth !== dimensions.width)
                ) {
                    removeIndexes.push(Number(files[i].getAttribute('data-id')));
                    this.setState({errorMessage: 'dimensions'});
                }
                if (i === files.length - 1) {
                    this.setState({submitWait: false});
                    // start removing the files if there are removeIndexes
                    if (removeIndexes.length) {
                        this.removeFiles(removeIndexes);
                    }
                }
            }
        })
    }
    removeFiles = (indexes) => {
        const { onUpload } = this.props;
        let newFileURLs = [...this.state.fileURLs];
        let newFiles = [...this.state.files];
        const filterIndex = (item, i) => {
            return !indexes.includes(i);
        }
        onUpload && onUpload(newFiles.filter(filterIndex));
        this.setState({
            fileURLs: newFileURLs.filter(filterIndex),
            files: newFiles.filter(filterIndex),
        })
    }
    removeFile = (i) => {
        const { onUpload } = this.props;
        if (i === -1) return;
        let newFileURLs = [...this.state.fileURLs];
        let newFiles = [...this.state.files];
        newFileURLs.splice(i, 1);
        newFiles.splice(i, 1);
        this.setState({
            fileURLs: newFileURLs,
            files: newFiles,
            errorMessage: null,
            submitWait: false,
        }, () => {
            onUpload && onUpload(newFiles);
            // if there are no files left, make the value of the hidden file input = null
            if (!newFiles.length) {
                const inputElement = document.getElementById('fileSelector');
                inputElement.value = null;
            }
        })
    }
    checkIfCanSubmit = () => {
        const { minFiles } = this.props;
        const { fileURLs } = this.state;
        return fileURLs && fileURLs.length >= minFiles;
    }
    getDimensionText = () => {
        const { intl, requiredImageWidth, requiredImageHeight } = this.props;
        let output = '';
        if (requiredImageWidth) output += `${intl.formatMessage({id: 'comp.fileUpload.widthRequiredText'})} ${requiredImageWidth}px`;
        if (requiredImageHeight && requiredImageWidth) output += ', '
        if (requiredImageHeight) output += `${intl.formatMessage({id: 'comp.fileUpload.heightRequiredText'})} ${requiredImageHeight}px`;
        return output;
    }
    render() {
        const { fileURLs, files, errorMessage, userDragging, submitWait } = this.state;
        const { intl, theme, width, height, count, formats, onSubmit, onCancel, showActions, margin, padding, Logo, requiredImageWidth, requiredImageHeight } = this.props;
        this.styles = styles(theme, width, height, errorMessage, userDragging, fileURLs, margin, padding);
        return (<div className={css(this.styles.allWrapper)}>
            <div className={css(this.styles.borderWrapper)} ref={this.dropArea}>
                <div className={css(this.styles.backgroundWrapper)}>
                    <div className={css(this.styles.innerWrapper)}>
                        {/* following input is hidden, dropArea click event listener activates it */}
                        <input id="fileSelector" type="file" multiple={count > 1} className={css(this.styles.invisibleFileSelector)} />
                        {!fileURLs.length && <div className={css(this.styles.dropWrapper)}>
                            <div> {/* this div is here for styling purposes, .dropWrapper is using display: grid */}
                                {!Logo ? 
                                    <ImageUploadIllustration 
                                        fill={theme && theme.color.themeColor} 
                                        darkTheme={theme && theme.color.name.includes('dark')} /> 
                                    : 
                                    <Logo />}
                                <div className={css(this.styles.mainText)}>
                                    {errorMessage ? (
                                        errorMessage === 'count' ? intl.formatMessage({id: `comp.fileUpload.countWarningOne`}) + count + intl.formatMessage({id: `comp.fileUpload.countWarning${count > 1 ? 'Files' : 'File'}`}) : 
                                        errorMessage === 'formats' ? intl.formatMessage({id: `comp.fileUpload.formatsWarning`}) : 
                                        errorMessage === 'size' ? intl.formatMessage({id: `comp.fileUpload.sizeWarning`}) : 
                                        errorMessage === 'dimensions' ? 
                                       ( intl.formatMessage({id: 'comp.fileUpload.incorrectDimensions'}) + ' ' + this.getDimensionText())
                                        : intl.formatMessage({id: `comp.fileUpload.errorWarning`})
                                    ) : intl.formatMessage({id: `comp.fileUpload.dropOrClick${count > 1 ? 'Plural' : ''}`})}
                                </div>
                                {(requiredImageWidth || requiredImageHeight) && <div className={css(this.styles.subTextDimensions)}>
                                    {this.getDimensionText()}
                                </div>}
                                <div className={css(this.styles.subText)}>{intl.formatMessage({id: `comp.fileUpload.supports`})} {formats.map(format => '.' + format).join(', ')}</div>
                            </div>
                        </div>}
                        {fileURLs.length > 0 && <div className={css(this.styles.filePreviewArea)}>
                            {fileURLs.map((file, i) => <div className={css(this.styles.fileContainer)} key={'file-upload-'+i}>
                                <div className={css(this.styles.deleteFileBtn)} onClick={() => this.removeFile(i)}>
                                    <CloseIcon width={5} height={5} fill={theme && theme.color.buttonFontColor} />
                                </div>
                                <img className={css(this.styles.fileImage) + ' imageUploadFile'} data-id={i} src={file} />
                            </div>)}
                        </div>}
                    </div>
                </div>
            </div>
            {showActions && <div className={css(this.styles.actionButtonContainer)}>
                <div className={css(this.styles.simpleButton, this.styles.simpleButtonCancel)} onClick={onCancel}>{intl.formatMessage({id: `comp.button.cancel`})}</div>
                {/* note: submitWait prevents submit button from working until various checks finish */}
                <div 
                    className={css(
                        this.styles.simpleButton, 
                        this.styles.simpleButtonSubmit, 
                        (submitWait || !this.checkIfCanSubmit()) && this.styles.simpleButtonDisabled,
                    )} 
                    onClick={() => onSubmit && !submitWait && this.checkIfCanSubmit() && onSubmit(files)}
                >
                    {!submitWait ? intl.formatMessage({id: `comp.button.submit`}) : intl.formatMessage({id: `comp.fileUpload.checking`}) + '...'}
                </div>
            </div>}
        </div>);
    }
}

FileUpload.defaultProps = {
    count: 1,
    minFiles: 1,
    formats: ['jpg', 'png', 'jpeg'],
    maxFileSize: 500000,
    showActions: false,
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(withRouter(connect(mapStateToProps)(FileUpload)));

const fileFadeIn = {
    'from': {
        opacity: 0,
        transform: 'translateX(20px)'
    },
    'to': {
        opacity: 1,
        transform: 'translateX(0px)'
    }
}
const fadeIn = {
    'from': { opacity: 0, },
    'to': { opacity: 1, }
}

const styles = (theme, width, height, errorMessage, userDragging, fileURLs, margin, padding) =>
    StyleSheet.create({
        allWrapper: {
            margin: margin || 0,
            padding: padding || 0,
        },
        borderWrapper: {
            width: width || '100%',
            height: height || 255, // using auto causes some weird border-bottom problems
            // customizing border dash magic
            // used this site to generate it: https://kovart.github.io/dashed-border-generator/
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='${!errorMessage ? hexToRGBA(theme.color.fontColor) : 'red'}' stroke-width='2' stroke-dasharray='6%2c 7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
            borderRadius: 15,
            cursor: fileURLs && fileURLs.length > 0 ? 'default' : 'pointer',
            overflow: 'hidden',
        },
        backgroundWrapper: {
            height: '100%',
            ':hover': {
                background: fileURLs && fileURLs.length > 0 ? 'transparent' : !errorMessage ? 'rgba(0, 0, 0, 0.03)' : 'rgba(255, 0, 0, 0.04)',
            }
        },
        innerWrapper: {
            height: '100%',
            background: userDragging ? 'rgba(0, 0, 0, 0.03)' : !errorMessage ? 'transparent' : 'rgba(255, 0, 0, 0.02)',
            textAlign: 'center',
            position: 'relative',
            maxHeight: '100%', 
            overflowY: 'auto',
        },
        dropWrapper: {
            height: '100%',
            padding: '40px 20px',
            pointerEvents: 'none',
            animationName: [fadeIn],
            animationDuration: '0.2s',
            display: 'grid',
            placeItems: 'center',
        },
        mainText: {
            color: theme && theme.color.fontColor,
            fontSize: 18,
            padding: '5px 0',
        },
        subText: {
            color: theme && !theme.color.name.includes('dark') ? theme.color.subFontColor : theme.color.fontColor,
            fontSize: 12,
            padding: '5px 0 0 0',
        },
        subTextDimensions: {
            color: theme && !theme.color.name.includes('dark') ? theme.color.subFontColor : theme.color.fontColor,
            fontSize: 12,
            padding: '5px 0 0 0',
            ':first-letter': {
                textTransform: 'capitalize',
            },
        },
        filePreviewArea: {
            display: 'flex', 
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: 15,
            padding: 15,
            alignItems: 'center',
            minHeight: 246, // needs to be -4
            '@media(max-width:500px)': {
                minHeight: 146
            }
        },
        fileContainer: {
            maxWidth: 300,
            maxHeight: 200,
            position: 'relative',
            animationName: [fileFadeIn],
            animationDuration: '0.2s',
            '@media(max-width:500px)': {
                maxWidth: 225,
                maxHeight: 150,
            }
        },
        deleteFileBtn: {
            width: 13,
            height: 13,
            backgroundColor: 'red',
            borderRadius: 13,
            color: theme && theme.color.buttonFontColor,
            position: 'absolute',
            right: -5, 
            top: -5,
            cursor: 'pointer',
            display: 'grid',
            placeItems: 'center',

        },
        fileImage: {
            maxWidth: 298, // needs to be 2 pixels lower due to border
            maxHeight: 198, // needs to be 2 pixels lower due to border
            border: `1px solid ${theme.color.borderColor}`,
            borderRadius: 5,
            '@media(max-width:500px)': {
                maxWidth: 223, // needs to be 2 pixels lower due to border
                maxHeight: 148, // needs to be 2 pixels lower due to border
            }
        },
        invisibleFileSelector: {
            visibility: 'hidden', 
            height: 0, 
            padding: 0, 
            margin: 0, 
            position: 'absolute', 
            left: 0,
        },
        actionButtonContainer: {
            marginTop: 15,
            display: 'flex', 
            justifyContent: 'space-between',
            '@media(max-width:500px)': {
                flexDirection: 'column'
            }
        },
        simpleButton: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: 17,
            borderRadius: 10,
            padding: '10px 15px',
            display: 'grid',
            placeItems: 'center',
            height: 48,
            minWidth: 125,
            transition: 'color 0.2s ease, opacity 0.2s ease, background 0.2s ease, width 0.2s ease',
            textTransform: 'capitalize',
            cursor: 'pointer',
            ':hover': {
                opacity: 0.8
            },
            '@media(max-width:500px)': {
                width: '100%'
            }
        },
        simpleButtonSubmit: {
            backgroundColor: theme.color.themeColor,
            color: theme.color.buttonFontColor,
        },
        simpleButtonCancel: {
            backgroundColor: theme.color.headerColor,
            border: `1px solid ${theme.color.themeColor}`,
            color: theme.color.themeColor,
            '@media(max-width:500px)': {
                marginBottom: 10,
            }
        },
        simpleButtonDisabled: {
            opacity: 0.4,
            backgroundColor: theme.color.headerColor,
            border: `1px solid ${theme.color.fontColor}`,
            color: theme.color.fontColor,
            cursor: 'default',
            ':hover': {
                opacity: 0.4
            }
        },
        simpleButtonCheckingNSWF: {
            opacity: 0.4,
            backgroundColor: theme.color.headerColor,
            border: `1px solid ${theme.color.fontColor}`,
            color: theme.color.fontColor,
            cursor: 'default',
            ':hover': {
                opacity: 0.4
            }
        }
    });
