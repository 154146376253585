import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { StyleSheet, css } from 'aphrodite';
import ToolTips from 'components/ToolTips';
import { generateTextID, hexToRGBA } from 'utilities';
import { ArrowDropUp, ArrowDropDown } from 'utilities/icons';

class TextInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requiredAlert: false,
            randomID: ''
        };
    }
    componentDidMount() {
        let randomID = generateTextID();
        this.setState({ randomID })
    }
    componentDidUpdate() {
        const { value, showRequired } = this.props;
        const { requiredAlert } = this.state;
        // if showRequired is true, the input will always be red if value is === ''
        showRequired && !requiredAlert && value === '' && this.setState({ requiredAlert: true });
        showRequired && requiredAlert && value !== '' && this.setState({ requiredAlert: false });
    }
    stepUpDownInput = (id, direction) => {
        const el = document.getElementById(id);
        if (!el) return;
        direction === 'up' ? el.stepUp() : el.stepDown();
    };
    render() {
        const {
            intl,
            theme,
            placeholder,
            value,
            onChange,
            type,
            width,
            title,
            fontSize,
            name,
            maxLength,
            showMaxLength,
            tooltip,
            required,
            margin,
            optionalText,
            noBorder,
            disabled,
            disabledStyling,
            onFocusProp,
            onBlurProp,
            titleFontSize,
            onKeyDown,
            onKeyUp,
            autoFocus,
            autoComplete,
            noPadding,
            hideNumberArrows
        } = this.props;
        const { requiredAlert, randomID } = this.state;
        this.styles = styles(theme, requiredAlert, fontSize, titleFontSize, disabledStyling);
        return (
            <div
                className={css(this.styles.inputWrapper)}
                style={{ width: width, margin: margin || (title !== '' ? '7px 0' : '0 0 7px 0') }}>
                {title && title !== '' && randomID && (
                    <div className={css(this.styles.titleTop)} id={randomID}>
                        {title}
                        {required && ` (${intl.formatMessage({ id: 'form.required' })})`}
                        {optionalText && ` ${intl.formatMessage({ id: 'form.option' })}`}
                    </div>
                )}
                {title && title !== '' && tooltip && tooltip !== '' && randomID && <ToolTips target={randomID} message={tooltip} />}
                <input
                    className={css(this.styles.inputClass) + ' inputClassComponent'}
                    onFocus={() => {
                        this.setState({ requiredAlert: false });
                        onFocusProp && onFocusProp();
                    }}
                    onBlur={() => {
                        required && this.setState({ requiredAlert: value === '' });
                        onBlurProp && onBlurProp();
                    }}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    style={{
                        fontSize: fontSize,
                        margin: title !== '' ? '7px auto 0 auto' : '0 auto',
                        padding: title !== '' ? '10px 0 7px 0' : noPadding ? '0' : '7px 0',
                        color: !requiredAlert ? theme.color.fontColor : 'red',
                        transition: '.2s color ease-in-out, .2s width ease-in-out, .2s border-bottom ease-in-out',
                        border: 'none',
                        borderBottom: noBorder ? 'none' : `1px solid ${!requiredAlert ? theme.color.borderColor : 'red'}`,
                        display: 'block',
                        width: '100%',
                        outline: 'none',
                        background: 'none',
                        opacity: disabledStyling ? 0.5 : 1
                    }}
                    id={'textInput' + randomID}
                    name={name}
                    type={type}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    disabled={disabled ? true : false}
                    autoFocus={autoFocus ? true : false}
                    autoComplete={autoComplete ? autoComplete : null}
                />
                {maxLength && showMaxLength && (
                    <div className={css(this.styles.characterCount)}>
                        {value.length} / {maxLength}
                    </div>
                )}
                {type === 'number' && !hideNumberArrows && (
                    <div className={css(this.styles.numberArrows)}>
                        <div
                            className={css(this.styles.numberArrowUp)}
                            onClick={() => this.stepUpDownInput('textInput' + randomID, 'up')}>
                            <ArrowDropUp
                                width={'100%'}
                                height={3}
                                fill={!requiredAlert ? theme.color.fontColor : 'red'}
                                pathStyle={{ transition: '.2s fill ease-in-out' }}
                                value={value}
                            />
                        </div>
                        <div className={css(this.styles.numberArrowSeparator)}></div>
                        <div
                            className={css(this.styles.numberArrowDown)}
                            onClick={() => this.stepUpDownInput('textInput' + randomID, 'down')}>
                            <ArrowDropDown
                                width={'100%'}
                                height={3}
                                fill={!requiredAlert ? theme.color.fontColor : 'red'}
                                pathStyle={{ transition: '.2s fill ease-in-out' }}
                                value={value}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

TextInput.defaultProps = {
    fontSize: 15,
    title: '',
    placeholder: '',
    value: 'Value',
    type: 'text',
    width: 'auto'
    // margin: '7px 0',
};

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(connect(mapStateToProps)(TextInput));

const styles = (theme, requiredAlert, fontSize, titleFontSize, disabledStyling) =>
    StyleSheet.create({
        inputWrapper: {
            position: 'relative',
            display: 'grid',
            transition: '.2s color ease-in-out, .2s width ease-in-out'
        },
        titleTop: {
            position: 'absolute',
            top: -4,
            backgroundColor: theme.color.headerColor,
            width: '100%',
            textAlign: 'left',
            color: !requiredAlert ? theme.color.themeColor : 'red',
            transition: '.2s color ease-in-out',
            fontSize: titleFontSize || 12,
            opacity: disabledStyling ? 0.5 : 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        inputClass: {
            '::placeholder': {
                transition: '.2s color ease-in-out, .2s width ease-in-out',
                color: !requiredAlert ? theme.color.subFontColor : `rgba(255,0,0,0.55)`,
                fontSize: fontSize
            },
            ':-webkit-autofill': {
                '-webkit-box-shadow': `0px 0px 0px 50px ${theme.color.compColor} inset !important`,
                '-webkit-text-fill-color': !requiredAlert ? theme.color.fontColor : 'red',
                caretColor: !requiredAlert ? theme.color.fontColor : 'red'
            }
        },
        characterCount: {
            position: 'absolute',
            right: 0,
            bottom: 7,
            marginRight: 10
        },
        numberArrows: {
            position: 'absolute',
            right: 2,
            bottom: 9,
            backgroundColor: !requiredAlert
                ? theme && hexToRGBA(theme.color.borderColor, 0.3)
                : hexToRGBA('#FF0000', 0.3),
            transition: '.2s background-color ease-in-out',
            borderRadius: 5,
            width: 21,
            height: 21,
            userSelect: 'none',
            overflow: 'hidden'
        },
        numberArrowUp: {
            display: 'grid',
            cursor: 'pointer',
            placeItems: 'center',
            height: 10,
            width: '100%',
            ':hover': {
                backgroundColor: 'rgba(0,0,0,0.04)'
            }
        },
        numberArrowDown: {
            display: 'grid',
            cursor: 'pointer',
            placeItems: 'center',
            height: 10,
            width: '100%',
            ':hover': {
                backgroundColor: 'rgba(0,0,0,0.06)'
            }
        },
        numberArrowSeparator: {
            borderTop: `1px solid ${!requiredAlert ? theme && theme.color.borderColor : hexToRGBA('#FF0000', 0.5)}`,
            transition: '.2s border-top ease-in-out'
        }
    });
