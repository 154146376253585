import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';
import moment from 'moment';

export const sendUserActivation = (userUUID = '', push = false) => {
    return {
        type: CONSTANTS.SEND_USER_ACTIVATION,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_ACTIVATION,
            push,
            data: { userUUID }
        }
    };
};

export const sendUserActivationAll = () => {
    return {
        type: CONSTANTS.SEND_USER_ACTIVATION_ALL,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_ACTIVATION_ALL,
            data: {}
        }
    };
};

export const setNotifyWarning = (data) => {
    return {
        type: CONSTANTS.SET_NOTIFY_WARNING,
        payload: {
            url: ENDPOINTS.ENDPOINT_NOTIFY_WARNING,
            data
        }
    };
};

export const unfreezeUserAcount = (userUUID = '', push = false) => {
    return {
        type: CONSTANTS.UNFREEZE_USER_ACCOUNT,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_UNFREEZE_ACCOUNT,
            push,
            refresh: true,
            data: {
                userUUID
            }
        }
    };
};

export const refreshUserPin = (userUUID = '', push = false) => {
    return {
        type: CONSTANTS.REFRESH_USER_PIN,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_REFRESH_PIN,
            push,
            data: {
                userUUID
            }
        }
    };
};

export const fetchUserLoginAttempts = (userUUID = '') => {
    return {
        type: CONSTANTS.FETCH_USER_LOGIN_ATTEMPTS,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_LOGIN_ATTEMPTS,
            data: { userUUID }
        }
    };
};

export const fetchUserPin = (userUUID = '') => {
    return {
        type: CONSTANTS.FETCH_USER_PIN,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_FETCH_PIN,
            data: { userUUID }
        }
    };
};

export const fetchUserFobs = (userUUID = 0) => {
    return {
        type: CONSTANTS.FETCH_USER_FOBS,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_FETCH_FOBS,
            data: { userUUID }
        }
    };
};

export const fetchUserShares = (userID = 0) => {
    return {
        type: CONSTANTS.FETCH_USER_SHARES,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_FETCH_SHARES,
            data: { userID }
        }
    }
}

export const setUserSharedUnitsHeaders = (data) => {
    return {
        type: CONSTANTS.SET_USER_SHARED_UNITS_HEADERS,
        payload: data
    };
};

export const saveUserSites = (userUUID = '', roles = [], remove_sites_uuid = [''], push = false) => {
    return {
        type: CONSTANTS.SAVE_USER_SITES,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_SAVE_SITES,
            push,
            refresh: true,
            data: {
                userUUID,
                roles,
                remove_sites_uuid
            }
        }
    };
};

export const createUser = (
    {
        firstName = '',
        lastName = '',
        email = '',
        phone = '',
        countryCode = '',
        role,
        accessCode = '',
        unitUUID = '',
        roleAtAllSites = false,
        loginType = ''
    },
    userSiteHoursSuiteUUID
) => {
    if (unitUUID === '') {
        unitUUID = null;
    }
    return {
        type: CONSTANTS.CREATE_USER,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_CREATE,
            required: {
                firstName,
                lastName,
                countryCode,
                role
            },
            data: {
                companyUUID: JSON.parse(localStorage.getItem('nse_login_data')).companyUUID,
                firstName,
                lastName,
                email,
                phone,
                countryCode,
                roleUUID: role,
                accessCode,
                unitUUID,
                roleAtAllSites: roleAtAllSites && JSON.parse(roleAtAllSites),
                loginType
            },
            userSiteHoursSuiteUUID
        }
    };
};

export const createServiceUser = ({
    firstName = '',
    lastName = '',
    email = '',
    phone = '',
    countryCode = '',
    role,
    accessCode = '',
    zoneUUIDs = [],
    expiration = ''
}) => {
    return {
        type: CONSTANTS.CREATE_SERVICE_USER,
        payload: {
            url: ENDPOINTS.ENDPOINT_SERVICE_USER_CREATE,
            required: {
                firstName,
                lastName,
                role
            },
            data: {
                companyUUID: JSON.parse(localStorage.getItem('nse_login_data')).companyUUID,
                firstName,
                lastName,
                email,
                phone,
                countryCode,
                roleUUID: role,
                accessCode,
                zoneUUIDs,
                expiration: expiration !== '' && expiration !== null ? moment(expiration).utc().format() : null
            }
        }
    };
};

export const deleteUser = (userUUID = '', push = true, support = false) => {
    return {
        type: CONSTANTS.DELETE_USER,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_DELETE,
            push,
            support,
            refresh: true,
            data: { userUUID }
        }
    };
};

export const deleteServiceUser = ({ userUUID = '', unitUUID = '', push = true }) => {
    return {
        type: CONSTANTS.DELETE_USER,
        payload: {
            url: '/user/serviceaccount/delete/',
            push,
            refresh: true,
            data: {
                userUUID,
                unitUUID
            }
        }
    };
};

export const removeUserFob = (fobUUID = '', userUUID = '', push = false) => {
    return {
        type: CONSTANTS.REMOVE_USER_FOB,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_REMOVE_FOB,
            push,
            refresh: true,
            data: {
                fobUUID,
                userUUID
            }
        }
    };
};

export const editUser = (userUUID = '', firstName = '', lastName = '', loginType = '', email = '') => {
    return {
        type: CONSTANTS.EDIT_USER,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_EDIT,
            required: {
                firstName,
                lastName
            },
            data: {
                userUUID,
                firstName,
                lastName,
                loginType,
                email
            }
        }
    };
};

export const editUserRole = (userUUID = '', role) => {
    if (role) {
        return {
            type: CONSTANTS.EDIT_USER_ROLE,
            payload: {
                url: ENDPOINTS.ENDPOINT_USER_ROLE_EDIT,
                role,
                data: {
                    userUUID,
                    roleUUID: role.uuid
                }
            }
        };
    }
};

export const editUserPhone = (userUUID = '', phone = '') => {
    return {
        type: CONSTANTS.EDIT_USER_PHONE,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_EDIT_PHONE,
            data: {
                userUUID,
                phone
            }
        }
    };
};

export const editUserEmail = (userUUID = '', email = '') => {
    return {
        type: CONSTANTS.EDIT_USER_EMAIL,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_EDIT,
            data: {
                userUUID,
                email
            }
        }
    };
};

export const editUserRoleAtAllSites = (userUUID = '', roleAtAllSites = false) => {
    return {
        type: CONSTANTS.EDIT_USER_EMAIL,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_EDIT,
            data: {
                userUUID,
                roleAtAllSites
            }
        }
    };
};

export const editUserKeyUse = (userUUID = '', preUnlockEnabled = false) => {
    return {
        type: CONSTANTS.EDIT_USER_KEY_USE,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_EDIT,
            data: {
                userUUID,
                preUnlockEnabled
            }
        }
    };
};

export const editUserView = (userUUID = '', simplifiedView = false, simpleBar = false) => {
    const permissions = JSON.parse(sessionStorage.getItem('user_data')).permissions;
    return {
        type: CONSTANTS.EDIT_USER_VIEW,
        payload: {
            url: permissions && permissions.includes('support_view_all_companies') ? ENDPOINTS.ENDPOINT_USER_EDIT : '/user/self/edit/',
            data: {
                userUUID,
                simplifiedView,
                simpleBar
            }
        }
    };
};

export const editUserPermission = ({ id = 0, permissions = [] }) => {
    return {
        type: CONSTANTS.EDIT_USER_PERMISSION,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_EDIT_PERMISSIONS,
            data: {
                userId: JSON.parse(id),
                userUUID: id,
                permissions
            }
        }
    };
};

export const editUserExpiration = (userId, expiration) => {
    return {
        type: CONSTANTS.EDIT_USER_EXPIRATION,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_EDIT,
            data: {
                userId,
                expiration: expiration !== '' && expiration !== null ? moment(expiration).utc().format() : null
            }
        }
    };
};

export const saveUserGatePermissions = (
    userId = 0,
    userUUID = '',
    permissions = { allHours: false, businessHours: true },
    push = false
) => {
    return {
        type: CONSTANTS.SAVE_USER_GATE_PERMISSIONS,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_GATE_PERMISSIONS,
            push,
            data: {
                userId,
                userUUID,
                permissions
            }
        }
    };
};

export const fetchSiteUserList = (filter, fetchStats = false, activeUsers = false, onlyCurrent = true, dashboardStatus = false) => {
    const permissions = JSON.parse(sessionStorage.getItem('user_data')).permissions;
    const userEmail = JSON.parse(sessionStorage.getItem('user_data')).email;
    return {
        type: CONSTANTS.FETCH_SITE_USER_LIST,
        payload: {
            url:
                permissions.includes('support_view_users') &&
                (userEmail.includes('@januseurope.com') ||
                    userEmail.includes('@ptiaustralasia.com') ||
                    userEmail.includes('@noke.com') ||
                    userEmail.includes('@janusintl.com'))
                    ? ENDPOINTS.ENDPOINT_SUPPORT_SITE_USER_LIST
                    : ENDPOINTS.ENDPOINT_SITE_USER_LIST,
            filter,
            data: {
                onlyCurrent
            },
            fetchStats,
            activeUsers,
            dashboardStatus
        }
    };
};

export const fetchRemovedUsers = ({ userUUID = '', range = {} }) => {
    return {
        type: CONSTANTS.FETCH_REMOVED_USERS,
        payload: {
            url: '/user/onsite/removed/read/',
            data:
                userUUID && userUUID != '' && range && range.begin && range.end
                    ? {
                          userUUID,
                          begin: range && `${range.begin}T00:00:00Z`,
                          end: range && `${range.end}T23:59:59Z`
                      }
                    : userUUID && userUUID != ''
                    ? {
                          userUUID
                      }
                    : {
                          begin: range && `${range.begin}T00:00:00Z`,
                          end: range && `${range.end}T23:59:59Z`
                      }
        }
    };
};

export const fetchUserDetails = (userUUID = '') => {
    return {
        type: CONSTANTS.FETCH_USER_DETAILS,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_DETAILS,
            data: { userUUID }
        }
    };
};

export const fetchUserPhoneDetails = (userUUID = '') => {
    const permissions = JSON.parse(sessionStorage.getItem('user_data')).permissions;
    return {
        type: CONSTANTS.FETCH_USER_PHONE_DETAILS,
        payload: {
            url: permissions.includes('support_view_all_companies') ? ENDPOINTS.ENDPOINT_SUPPORT_USER_PHONE_DETAILS : ENDPOINTS.ENDPOINT_USER_PHONE_DETAILS,
            data: { userUUID }
        }
    };
};

export const confirmUserAccount = (userId = 0, key = 'token', push = true) => {
    return {
        type: CONSTANTS.CONFIRM_USER_ACCOUNT,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_CONFIRM,
            push,
            data: {
                userId,
                key
            }
        }
    };
};

export const saveUserDailyHours = ({ userId = 0, siteId = 0, hours = {} }) => {
    return {
        type: CONSTANTS.SAVE_USER_DAILY_HOURS,
        payload: {
            url: '/user/hours/suite/update/',
            data: {
                userId,
                siteId,
                hours
            }
        }
    };
};

export const deleteUserDailyHours = (userId = 0, siteId = 0) => {
    return {
        type: CONSTANTS.DELETE_USER_DAILY_HOURS,
        payload: {
            url: '/user/hours/suite/delete/',
            data: {
                userId,
                siteId
            }
        }
    };
};

export const removeOnsiteUser = (data) => {
    return {
        type: CONSTANTS.REMOVE_ONSITE_USER,
        payload: {
            url: '/user/onsite/update/',
            data: data
        }
    };
};

export const userAcknowledgeTraining = (data) => {
    return {
        type: CONSTANTS.USER_ACKNOWLEDGE_TRAINING,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_ACKNOWLEDGE_TRAINING,
            data: {
                acknowledged: data
            }
        }
    };
};

export const fetchUserUnits = (userId = 0) => {
    return {
        type: CONSTANTS.FETCH_USER_UNITS,
        payload: {
            url: ENDPOINTS.ENDPOINT_FETCH_USER_UNITS,
            data: {
                userUUID: userId
            }
        }
    };
};

export const sendUserAccesscode = (userUUID = '') => {
    return {
        type: CONSTANTS.SEND_USER_ACCESSCODE,
        payload: {
            url: '/site/accesscodes/send/',
            data: {
                userUUID
            }
        }
    };
};

export const editUserSettings = (userObject) => {
    return {
        type: CONSTANTS.EDIT_USER_SETTINGS,
        payload: {
            url: '/user/settings/',
            user: userObject.uuid,
            data: {
                language: userObject.language,
                temperatureScale: userObject.temperatureScale,
                preferredDateFormat: userObject.preferredDateFormat,
                useSiteTime: typeof userObject.useSiteTime === 'string' ? userObject.useSiteTime : userObject.useSiteTime == true ? 'true' : 'false'
            }
        }
    };
};

export const setSiteUserList = (data = []) => {
    return {
        type: CONSTANTS.SET_SITE_USER_LIST,
        payload: data
    };
};

export const setRemovedUsers = (data = []) => {
    return {
        type: CONSTANTS.SET_REMOVED_USERS,
        payload: data
    };
};

export const setCompanyUserListStats = (data = []) => {
    return {
        type: CONSTANTS.SET_COMPANY_USER_LIST_STATS,
        payload: data
    };
};

export const setSiteUserListStats = (data = []) => {
    return {
        type: CONSTANTS.SET_SITE_USER_LIST_STATS,
        payload: data
    };
};

export const setSiteUserListStatusStats = (data = []) => {
    return {
        type: CONSTANTS.SET_SITE_USER_LIST_STATUS_STATS,
        payload: data
    };
};

export const setSiteUserListServiceStats = (data = []) => {
    return {
        type: CONSTANTS.SET_SITE_USER_LIST_SERVICE_STATS,
        payload: data
    };
};

export const setUserDetails = (data = null) => {
    return {
        type: CONSTANTS.SET_USER_DETAILS,
        payload: data
    };
};

export const setUserPhoneDetails = (data = null) => {
    return {
        type: CONSTANTS.SET_USER_PHONE_DETAILS,
        payload: data
    };
};

export const setUserPin = (data = null) => {
    return {
        type: CONSTANTS.SET_USER_PIN,
        payload: data
    };
};

export const setUserLoginAttempts = (data = null) => {
    return {
        type: CONSTANTS.SET_USER_LOGIN_ATTEMPTS,
        payload: data
    };
};

export const setUserFobs = (data = null) => {
    return {
        type: CONSTANTS.SET_USER_FOBS,
        payload: data
    };
};

export const setUserShares = (data = null) => {
    return {
        type: CONSTANTS.SET_USER_SHARES,
        payload: data
    }
}

export const setUserHeaders = (data) => {
    return {
        type: CONSTANTS.SET_USER_HEADERS,
        payload: data
    };
};

export const setUserHeadersOld = (data) => {
    return {
        type: CONSTANTS.SET_USER_HEADERS_OLD,
        payload: data
    };
};

export const setUserSiteHeaders = (data) => {
    return {
        type: CONSTANTS.SET_USER_SITE_HEADERS,
        payload: data
    };
};

export const setUserPermissionHeaders = (data) => {
    return {
        type: CONSTANTS.SET_USER_PERMISSION_HEADERS,
        payload: data
    };
};

export const setUserSharedHeaders = (data) => {
    return {
        type: CONSTANTS.SET_USER_SHARED_HEADERS,
        payload: data
    };
};

export const setUserFobHeaders = (data) => {
    return {
        type: CONSTANTS.SET_USER_FOB_HEADERS,
        payload: data
    };
};

export const setUserFilters = (data) => {
    return {
        type: CONSTANTS.SET_USER_FILTERS,
        payload: data
    };
};

export const setUserFiltersOld = (data) => {
    return {
        type: CONSTANTS.SET_USER_FILTERS_OLD,
        payload: data
    };
};

export const setActiveUser = (data) => {
    return {
        type: CONSTANTS.SET_USER_ACTIVE,
        payload: data
    };
};

export const setUserUnits = (data) => {
    return {
        type: CONSTANTS.SET_USER_UNITS,
        payload: data
    };
};

export const setUserKeypadStatus = (data) => {
    return {
        type: CONSTANTS.SET_USER_KEYPAD_STATUS,
        payload: data
    };
};

export const setUserFilterStatus = (data) => {
    return {
        type: CONSTANTS.SET_USER_FILTER_STATUS,
        payload: data
    };
};

export const setUserFilterService = (data) => {
    return {
        type: CONSTANTS.SET_USER_FILTER_SERVICE,
        payload: data
    };
};

export const setUserFilterSchedule = (data) => {
    return {
        type: CONSTANTS.SET_USER_FILTER_SCHEDULE,
        payload: data
    };
};

export const setUserEditedSchedule = (data) => {
    return {
        type: CONSTANTS.SET_USER_EDITED_SCHEDULE,
        payload: data
    };
};

export const editSelf2Factor = (data) => {
    return {
        type: CONSTANTS.EDIT_SELF_2FACTOR,
        payload: {
            url: '/user2factor/update/self/',
            data
        }
    };
};

export const edit2Factor = (data) => {
    return {
        type: CONSTANTS.EDIT_2FACTOR,
        payload: {
            url: '/user2factor/update/',
            data
        }
    };
};

export const fetchSupportUsers = () => {
    return {
        type: CONSTANTS.FETCH_SUPPORT_USERS,
        payload: {
            url: '/supportusers/read/all/',
            data: {}
        }
    };
};

export const fetchSupportUserDetails = (id = 0) => {
    return {
        type: CONSTANTS.FETCH_SUPPORT_USER_DETAILS,
        payload: {
            url: '/supportusers/read/all/',
            data: {
                id: Number(id)
            }
        }
    };
};

export const setSupportUsers = (data) => {
    return {
        type: CONSTANTS.SET_SUPPORT_USERS,
        payload: data
    };
};

export const exportUsers = ({ toEmail = '', tenantsUsingFobs = false, shares = false, userAccess = false }) => {
    return {
        type: CONSTANTS.EXPORT_USERS,
        payload: {
            url: '/site/exports/',
            data: {
                toEmail,
                tenantsUsingFobs,
                shares,
                userAccess
            }
        }
    };
};

export const exportRemovedUsers = (data) => {
    return {
        type: CONSTANTS.EXPORT_REMOVED_USERS,
        payload: {
            url: '/user/onsite/removed/read/',
            data
        }
    };
};

export const removeManagerShare = (unitID = 0) => {
    return {
        type: CONSTANTS.REMOVE_MANAGER_SHARE,
        payload: {
            url: '/user/share/manager/remove/',
            data: {
                unitID: Number(unitID)
            }
        }
    };
};

export const fetchOnsiteUsers = (map = false) => {
    return {
        type: CONSTANTS.FETCH_ONSITE_USERS,
        payload: {
            url: '/user/onsite/read/',
            data: {},
            map
        }
    };
};

export const setOnsiteUsers = (data) => {
    return {
        type: CONSTANTS.SET_ONSITE_USERS,
        payload: data
    };
};

export const fetchOnsiteUsersAll = () => {
    return {
        type: CONSTANTS.FETCH_ONSITE_USERS_ALL,
        payload: {
            url: '/user/onsite/read/all/',
            data: {}
        }
    };
};

export const setOnsiteUsersAll = (data) => {
    return {
        type: CONSTANTS.SET_ONSITE_USERS_ALL,
        payload: data
    };
};

export const fetchManagerSites = () => {
    return {
        type: CONSTANTS.FETCH_MANAGER_SITES,
        payload: {
            url: '/manager/sites/read/',
            data: {}
        }
    };
};

export const setManagerSites = (data) => {
    return {
        type: CONSTANTS.SET_MANAGER_SITES,
        payload: data
    };
};

export const fetchEmployeesOnline = () => {
    return {
        type: CONSTANTS.FETCH_EMPLOYEES_ONLINE,
        payload: {
            url: '/employees/online/read/',
            data: {}
        }
    };
};

export const setEmployeesOnline = (data) => {
    return {
        type: CONSTANTS.SET_EMPLOYEES_ONLINE,
        payload: data
    };
};

export const setRemovedUsersDates = (data) => {
    return {
        type: CONSTANTS.SET_REMOVED_USERS_DATES,
        payload: data
    };
};

export const fetchLoiteringUsers = (minutes) => {
    return {
        type: CONSTANTS.FETCH_LOITERING_USERS,
        payload: {
            url: '/users/loitering/read/',
            data: {
                minutes: Number(minutes)
            }
        }
    };
};

export const setLoiteringUsers = (data) => {
    return {
        type: CONSTANTS.SET_LOITERING_USERS,
        payload: data
    };
};

export const fetchTransferringUsers = () => {
    return {
        type: CONSTANTS.FETCH_TRANSFERRING_USERS,
        payload: {
            url: '/users/transfer/',
            data: {}
        }
    };
};

export const setTransferringUsers = (data) => {
    return {
        type: CONSTANTS.SET_TRANSFERRING_USERS,
        payload: data
    };
};

export const extendUserTransfer = (userUUID, intl) => {
    return {
        type: CONSTANTS.EXTEND_USER_TRANSFER,
        payload: {
            url: '/site/unit/transfer/extend/',
            data: {
                userUUID
            },
            intl
        }
    };
};

export const setUserRoleActiveFilter = (data) => {
    return {
        type: CONSTANTS.SET_USER_ROLE_ACTIVE_FILTER,
        payload: data
    };
};

export const fetchTenantUnits = () => {
    return {
        type: CONSTANTS.FETCH_TENANT_UNITS,
        payload: {
            url: '/user/locks/',
            data: {}
        }
    };
};

export const setTenantUnits = (data) => {
    return {
        type: CONSTANTS.SET_TENANT_UNITS,
        payload: data
    };
};

export const fetchTenantShares = () => {
    return {
        type: CONSTANTS.FETCH_TENANT_SHARES,
        payload: {
            url: '/user/shares/',
            data: {}
        }
    };
};

export const setTenantShares = (data) => {
    return {
        type: CONSTANTS.SET_TENANT_SHARES,
        payload: data
    };
};

export const setCurrentUserFilter = (data) => {
    return {
        type: CONSTANTS.SET_CURRENT_USER_FILTER,
        payload: data
    };
};

export const setTransferHeaders = (data) => {
    return {
        type: CONSTANTS.SET_TRANSFER_HEADERS,
        payload: data
    };
};