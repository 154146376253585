import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const saveSiteHours = ({ open = '', close = '', site = {} }) => {
    return {
        type: CONSTANTS.SAVE_SITE_HOURS,
        payload: {
            url: ENDPOINTS.ENDPOINT_SITE_HOURS,
            site: site,
            data: {
                open,
                close
            }
        }
    };
};

export const saveSiteDailyHours = ({ site = {}, hours = {}, wizard = null }) => {
    return {
        type: CONSTANTS.SAVE_SITE_DAILY_HOURS,
        payload: {
            url: '/site/hours/suite/update/',
            site: site,
            wizard: wizard,
            data: { hours, siteUUID: site.uuid }
        }
    };
};

export const fetchSettings = () => {
    return {
        type: CONSTANTS.FETCH_SETTINGS,
        payload: {
            url: '/',
            data: {}
        }
    };
};

export const fetchPmsDetails = () => {
    return {
        type: CONSTANTS.FETCH_PMS_DETAILS,
        payload: {
            url: '/site/setup/pms/read/',
            data: {}
        }
    };
};

export const editSettings = (data) => {
    return {
        type: CONSTANTS.EDIT_SETTINGS,
        payload: {
            url: '/admin/setting/update/',
            data
        }
    };
};

export const setSettings = (data) => {
    return {
        type: CONSTANTS.SET_SETTINGS,
        payload: data
    };
};

export const setPmsDetails = (data) => {
    return {
        type: CONSTANTS.SET_PMS_DETAILS,
        payload: data
    };
};

export const setActiveSetting = (data) => {
    return {
        type: CONSTANTS.SET_SETTINGS_ACTIVE,
        payload: data
    };
};

export const uploadImage = (data, wizard = false) => {
    return {
        type: CONSTANTS.UPLOAD_IMAGE,
        payload: {
            url: '/company/image/',
            data,
            wizard
        }
    };
};

export const uploadSiteImage = (data, wizard) => {
    return {
        type: CONSTANTS.UPLOAD_SITE_IMAGE,
        payload: {
            url: '/site/image/',
            wizard,
            data
        }
    };
};

export const createSite = ({
    name = '',
    city = '',
    closeTime = '17:00',
    openTime = '09:00',
    companyUUID = '',
    country = '',
    email = '',
    latitude = '0',
    longitude = '0',
    mainUrl = '',
    motion_limit = '2',
    motion_time_minutes = '1',
    notifications_on = 'false',
    entryNotificationsEnabled = 'false',
    overlockNotifications = 'true',
    adminOverlockNotification = 'false',
    manualNotificationsEnabled = 'false',
    motionNotificationsEnabled = 'false',
    unitNotificationsEnabled = 'false',
    paymentUrl = '',
    phone = '',
    pms = '',
    postalCode = '',
    state = '',
    streetAddress = '',
    suite = '',
    salesOrderNumber = '',
    opportunityNumber = '',
    timeZone = '',
    language = 'English',
    temperatureScale = 'fahrenheit',
    sitePreferredDateFormat = 'MM/DD/YYYY',
    scheduledUnlockKeysEnabled = 'false',
    syncTextEnabled = 'false',
    useBlacklist = 'false',
    moveOutFeatureEnabled = 'false',
    motionDelay = '',
    waitAfterNotification = '',
    offlineExpirationMinutes = '',
    ignoreLeadingZero = 'false',
    wakeOnMotion = 'false',
    allowManagerShares = 'false',
    installerAccountsEnabled = 'false',
    timeNotSetFailSecure = 'false',
    tokenTime = 15,
    temperatureNotificationsOn = 'false',
    temperatureMin = '',
    temperatureMax = '',
    eula = '',
    allowMassUpdates = 'true',
    allowTenantHoursUpdates = 'false',
    actualRadius = '',
    wpSiteVersion = '',
    preUnlockEnabled = 'false',
    allowServiceFobs = 'false',
    useFobSpecificSchedule = 'true',
    restrictOverlockedEntry = 'false',
    allowEmployeeRental = 'true',
    rolloutTier = 2,
    onSiteTimeout = '0',
    networkSSID = '',
    networkPassphrase = '',
    allowDailyAccessCodes = 'false',
    enableDualActivation = 'true',
    failedLoginNotifications = 'false',
}, redirect=true) => {
    return {
        type: CONSTANTS.CREATE_SITE,
        payload: {
            url: '/site/setup/',
            required: {
                name,
                streetAddress,
                city,
                //state,
                postalCode,
                country,
                timeZone,
                phone,
                email,
                motion_limit,
                motion_time_minutes,
                companyUUID
            },
            data: {
                redirect,
                sites: [
                    {
                        name,
                        city,
                        closeTime: `1970-01-01T${closeTime}:00Z`,
                        openTime: `1970-01-01T${openTime}:00Z`,
                        companyUUID,
                        country,
                        email,
                        latitude,
                        longitude,
                        mainUrl,
                        motion_limit,
                        motion_time_minutes,
                        notifications_on,
                        entryNotificationsEnabled,
                        overlockNotifications,
                        adminOverlockNotification,
                        manualNotificationsEnabled,
                        motionNotificationsEnabled,
                        unitNotificationsEnabled,
                        paymentUrl,
                        phone,
                        pms,
                        postalCode,
                        streetAddress,
                        state,
                        suite,
                        salesOrderNumber,
                        opportunityNumber,
                        timeZone: timeZone.split(' ').join('_'),
                        language,
                        temperatureScale,
                        sitePreferredDateFormat,
                        scheduledUnlockKeysEnabled,
                        syncTextEnabled,
                        useBlacklist,
                        moveOutFeatureEnabled,
                        motionDelay,
                        waitAfterNotification,
                        installerAccountsEnabled,
                        eula,
                        offlineExpirationMinutes: Number(offlineExpirationMinutes),
                        ignoreAccessCodesLeadingZeros: ignoreLeadingZero,
                        wakeOnMotion,
                        allowManagerShares,
                        timeNotSetFailSecure,
                        tokenTime: Number(tokenTime),
                        temperatureNotificationsOn,
                        temperatureMin,
                        temperatureMax,
                        allowMassUpdates,
                        allowTenantHoursUpdates,
                        actualRadius,
                        wpSiteVersion,
                        preUnlockEnabled,
                        allowServiceFobs,
                        useFobSpecificSchedule,
                        restrictOverlockedEntry,
                        rolloutTier: Number(rolloutTier),
                        allowEmployeeRental,
                        onSiteTimeout,
                        networkSSID,
                        networkPassphrase,
                        allowDailyAccessCodes,
                        enableDualActivation,
                        failedLoginNotifications,
                    }
                ]
            }
        }
    };
};

export const createBulkSite = (sites) => {
    return {
        type: CONSTANTS.CREATE_BULK_SITE,
        payload: {
            url: '/site/setup/',
            data: {
                sites
            }
        }
    };
};

export const editSite = (data, wizard, silent = false) => {
    return {
        type: CONSTANTS.EDIT_SITE,
        payload: {
            url: '/site/setup/',
            data: {
                sites: data
            },
            wizard,
            silent
        }
    };
};

export const editSiteSilently = (data, wizard) => {
    return {
        type: CONSTANTS.EDIT_SITE_SILENTLY,
        payload: {
            url: '/site/setup/',
            data: {
                sites: data
            },
            wizard
        }
    };
};

export const editSiteLocation = (data) => {
    return {
        type: CONSTANTS.EDIT_SITE_LOCATION,
        payload: {
            url: '/site/setup/',
            data: {
                sites: data
            }
        }
    };
};

export const fetchFeatureFlags = (data) => {
    return {
        type: CONSTANTS.FETCH_FEATURE_FLAGS,
        payload: {
            url: '/featureflags/read/all/',
            data
        }
    };
};

export const setFeatureFlags = (data) => {
    return {
        type: CONSTANTS.SET_FEATURE_FLAGS,
        payload: data
    };
};

export const fetchFeatureFlagAssignments = () => {
    return {
        type: CONSTANTS.FETCH_FEATURE_FLAG_ASSIGNMENTS,
        payload: {
            url: '/featureflags/assignments/read/site/'
        }
    };
};

export const setFeatureFlagAssignments = (data) => {
    return {
        type: CONSTANTS.SET_FEATURE_FLAG_ASSIGNMENTS,
        payload: data
    };
};

export const editFeatureFlags = (data) => {
    return {
        type: CONSTANTS.EDIT_FEATURE_FLAGS,
        payload: {
            url: '/featureflags/assignments/update/',
            data
        }
    };
};

export const createFeatureFlag = ({ name = '', description = '' }) => {
    return {
        type: CONSTANTS.CREATE_FEATURE_FLAG,
        payload: {
            url: '/featureflags/create/',
            required: {
                name,
                description
            },
            data: {
                name,
                description
            }
        }
    };
};

export const setFeatureFlag = (data) => {
    return {
        type: CONSTANTS.SET_FEATURE_FLAG,
        payload: data
    };
};

export const editFeatureFlag = (data) => {
    return {
        type: CONSTANTS.EDIT_FEATURE_FLAG,
        payload: {
            url: '/featureflags/update/',
            data
        }
    };
};

export const deleteFeatureFlag = (data) => {
    return {
        type: CONSTANTS.DELETE_FEATURE_FLAG,
        payload: {
            url: '/featureflags/delete/',
            data
        }
    };
};

export const fetchSiteHours = () => {
    return {
        type: CONSTANTS.FETCH_SITE_HOURS,
        payload: {
            url: ENDPOINTS.ENDPOINT_FETCH_SITE_HOURS,
            data: {}
        }
    };
};

export const setSiteHours = (data) => {
    return {
        type: CONSTANTS.SET_SITE_HOURS,
        payload: data
    };
};

export const setCreateSiteHours = (data) => {
    return {
        type: CONSTANTS.SET_SITE_CREATE_HOURS,
        payload: data
    };
};

export const createSchedule = ({ name = '', hours = {}, siteUUID = '' }, wizard, another = false) => {
    return {
        type: CONSTANTS.CREATE_SCHEDULE,
        payload: {
            url: '/hours/suite/create/',
            required: {
                name
            },
            wizard,
            another,
            data: {
                siteUUID,
                name,
                hours
            }
        }
    };
};

export const editSchedule = (data) => {
    const featureflagAssignments = JSON.parse(sessionStorage.getItem('user_data')).featureflagAssignments;
    return {
        type: CONSTANTS.EDIT_SCHEDULE,
        payload: {
            url:
                featureflagAssignments &&
                featureflagAssignments.includes('threeScheduleEnabled') &&
                featureflagAssignments.includes('newScheduleEnabled')
                    ? '/site/suite/update/'
                    : '/hours/suite/update/',
            data:
                featureflagAssignments &&
                featureflagAssignments.includes('threeScheduleEnabled') &&
                featureflagAssignments.includes('newScheduleEnabled')
                    ? {
                          userSiteHoursSuiteID: Number(data.userSiteHoursSuiteID),
                          userSiteHoursSuiteUUID: String(data.userSiteHoursSuiteUUID),
                          name: data.name,
                          position: data.position,
                          hours: data.hours
                      }
                    : {
                          siteUUID: JSON.parse(localStorage.getItem('nse_login_data')).siteUUID,
                          name: data.name,
                          hours: data.hours,
                          userHoursSuiteUUID: data.userHoursSuiteUUID
                      }
        }
    };
};

export const deleteSchedule = (data) => {
    return {
        type: CONSTANTS.DELETE_SCHEDULE,
        payload: {
            url: '/hours/suite/delete/',
            data: {
                userHoursSuiteUUID: data
            }
        }
    };
};

export const fetchSchedule = (data) => {
    return {
        type: CONSTANTS.FETCH_SCHEDULE,
        payload: {
            url: '/hours/suite/read/',
            data: {
                userHoursSuiteUUID: data,
                siteUUID: JSON.parse(localStorage.getItem('nse_login_data')).siteUUID
            }
        }
    };
};

export const setSchedule = (data) => {
    return {
        type: CONSTANTS.SET_SCHEDULE,
        payload: data
    };
};

export const fetchScheduleUsers = (data) => {
    return {
        type: CONSTANTS.FETCH_SCHEDULE_USERS,
        payload: {
            url: '/hours/site/suite/read/allusers/',
            data: {
                userSiteHoursSuiteUUID: data
            }
        }
    };
};

export const setScheduleUsers = (data) => {
    return {
        type: CONSTANTS.SET_SCHEDULE_USERS,
        payload: data
    };
};

export const fetchAllSchedules = () => {
    const featureflagAssignments = JSON.parse(sessionStorage.getItem('user_data')).featureflagAssignments;
    return {
        type: CONSTANTS.FETCH_ALL_SCHEDULES,
        payload: {
            url:
                featureflagAssignments &&
                featureflagAssignments.includes('threeScheduleEnabled') &&
                featureflagAssignments.includes('newScheduleEnabled')
                    ? '/site/suite/read/'
                    : '/hours/suite/read/site/',
            data:
                featureflagAssignments &&
                featureflagAssignments.includes('threeScheduleEnabled') &&
                featureflagAssignments.includes('newScheduleEnabled')
                    ? {}
                    : { siteUUID: JSON.parse(localStorage.getItem('nse_login_data')).siteUUID }
        }
    };
};

export const setAllSchedules = (data) => {
    return {
        type: CONSTANTS.SET_ALL_SCHEDULES,
        payload: data
    };
};

export const fetchHolidayHours = () => {
    return {
        type: CONSTANTS.FETCH_HOLIDAY_HOURS,
        payload: {
            url: '/holidays/read/',
            data: {}
        }
    };
};

export const addHoliday = (data) => {
    return {
        type: CONSTANTS.ADD_HOLIDAY,
        payload: {
            url: '/holidays/update/',
            data
        }
    };
};

export const deleteHoliday = (data) => {
    return {
        type: CONSTANTS.DELETE_HOLIDAY,
        payload: {
            url: '/holidays/delete/',
            data
        }
    };
};

export const setHolidayHours = (data) => {
    return {
        type: CONSTANTS.SET_HOLIDAY_HOURS,
        payload: data
    };
};

export const updateUserSchedule = ({
    userUUID = '',
    userHoursSuiteUUID = '',
    userSiteHoursSuiteUUID = '',
    stayPage = false
}) => {
    const featureflagAssignments = JSON.parse(sessionStorage.getItem('user_data')).featureflagAssignments;
    return {
        type: CONSTANTS.UPDATE_USER_SCHEDULE,
        payload: {
            url:
                featureflagAssignments &&
                featureflagAssignments.includes('threeScheduleEnabled') &&
                featureflagAssignments.includes('newScheduleEnabled')
                    ? '/user/hours/site/suite/update/'
                    : '/user/hours/suite/update/',
            data:
                featureflagAssignments &&
                featureflagAssignments.includes('threeScheduleEnabled') &&
                featureflagAssignments.includes('newScheduleEnabled')
                    ? {
                          userSiteHoursSuiteUUID: userSiteHoursSuiteUUID,
                          userUUID
                      }
                    : {
                          userHoursSuiteUUID: userHoursSuiteUUID,
                          userUUID
                      },
            stayPage
        }
    };
};

export const deleteUserSchedule = ({ userUUID = '', userHoursSuiteUUID = '' }) => {
    const featureflagAssignments = JSON.parse(sessionStorage.getItem('user_data')).featureflagAssignments;
    return {
        type: CONSTANTS.DELETE_USER_SCHEDULE,
        payload: {
            url:
                featureflagAssignments &&
                featureflagAssignments.includes('threeScheduleEnabled') &&
                featureflagAssignments.includes('newScheduleEnabled')
                    ? '/user/hours/site/suite/delete/'
                    : '/user/hours/suite/delete/',
            data:
                featureflagAssignments &&
                featureflagAssignments.includes('threeScheduleEnabled') &&
                featureflagAssignments.includes('newScheduleEnabled')
                    ? {
                          userUUID
                      }
                    : {
                          userUUID,
                          userHoursSuiteUUID
                      }
        }
    };
};

export const sendSiteHours = () => {
    return {
        type: CONSTANTS.SEND_SITE_HOURS,
        payload: {
            url: '/site/hours/send/',
            data: {}
        }
    };
};

export const fetchMessages = ({ language = '', companyUUID = '1' }) => {
    return {
        type: CONSTANTS.FETCH_MESSAGES,
        payload: {
            url: '/company/messages/SMS/read/',
            data: {
                companyUUID: companyUUID,
                language: language
            }
        }
    };
};

export const setMessages = (data) => {
    return {
        type: CONSTANTS.SET_MESSAGES,
        payload: data
    };
};

export const fetchSiteMessages = () => {
    return {
        type: CONSTANTS.FETCH_SITE_MESSAGES,
        payload: {
            url: '/site/messages/read/',
            data: {}
        }
    };
};

export const fetchSiteSMSMessages = (language) => {
    return {
        type: CONSTANTS.FETCH_SITE_SMS_MESSAGES,
        payload: {
            url: '/site/messages/SMS/read/',
            data: { language: language }
        }
    };
};

export const fetchSiteEmailMessages = (language) => {
    return {
        type: CONSTANTS.FETCH_SITE_EMAIL_MESSAGES,
        payload: {
            url: '/site/messages/email/read/',
            data: { language: language }
        }
    };
};

export const fetchSitePushMessages = (language) => {
    return {
        type: CONSTANTS.FETCH_SITE_PUSH_MESSAGES,
        payload: {
            url: '/site/messages/push/read/',
            data: { language: language }
        }
    };
};

export const fetchSiteNotificationMessages = (language) => {
    return {
        type: CONSTANTS.FETCH_SITE_NOTIFICATION_MESSAGES,
        payload: {
            url: '/site/messages/internal/read/',
            data: { language: language }
        }
    };
};

export const setSiteMessages = (data) => {
    return {
        type: CONSTANTS.SET_SITE_MESSAGES,
        payload: data
    };
};

export const setSiteVariables = (data) => {
    return {
        type: CONSTANTS.SET_SITE_VARIABLES,
        payload: data
    };
};

export const fetchNotificationMessages = ({ language = '', companyUUID = '1' }) => {
    return {
        type: CONSTANTS.FETCH_NOTIFICATION_MESSAGES,
        payload: {
            url: '/company/messages/notifications/read/',
            data: {
                companyUUID: companyUUID,
                language: language
            }
        }
    };
};

export const setNotificationMessages = (data) => {
    return {
        type: CONSTANTS.SET_NOTIFICATION_MESSAGES,
        payload: data
    };
};

export const setSiteSpecificSMSMessages = (data) => {
    return {
        type: CONSTANTS.SET_SITE_SPECIFIC_SMS_MESSAGES,
        payload: data
    };
};

export const setSiteSpecificEmailMessages = (data) => {
    return {
        type: CONSTANTS.SET_SITE_SPECIFIC_EMAIL_MESSAGES,
        payload: data
    };
};

export const setSiteSpecificPushMessages = (data) => {
    return {
        type: CONSTANTS.SET_SITE_SPECIFIC_PUSH_MESSAGES,
        payload: data
    };
};

export const setSiteSpecificNotificationMessages = (data) => {
    return {
        type: CONSTANTS.SET_SITE_SPECIFIC_NOTIFICATION_MESSAGES,
        payload: data
    };
};

export const fetchLanguages = () => {
    return {
        type: CONSTANTS.FETCH_LANGUAGES,
        payload: {
            url: '/company/messages/languages/',
            data: {}
        }
    };
};

export const setLanguages = (data) => {
    return {
        type: CONSTANTS.SET_LANGUAGES,
        payload: data
    };
};

export const editMessages = ({ messages = [], language = '' }, companyUUID) => {
    return {
        type: CONSTANTS.EDIT_MESSAGES,
        payload: {
            url: '/company/messages/SMS/update/',
            data: {
                companyUUID: companyUUID,
                language: language,
                smsMessages: messages
            }
        }
    };
};

export const editSiteMessages = (data) => {
    return {
        type: CONSTANTS.EDIT_SITE_MESSAGES,
        payload: {
            url: '/site/messages/update/',
            data: {
                siteMessages: data
            }
        }
    };
};

export const editSiteSMSMessages = (data) => {
    return {
        type: CONSTANTS.EDIT_SITE_SMS_MESSAGES,
        payload: {
            url: '/site/messages/SMS/update/',
            data: data
        }
    };
};

export const editSiteNotificationMessages = (data) => {
    return {
        type: CONSTANTS.EDIT_SITE_NOTIFICATION_MESSAGES,
        payload: {
            url: '/site/messages/internal/update/',
            data: data
        }
    };
};

export const editSiteEmailMessages = (data) => {
    return {
        type: CONSTANTS.EDIT_SITE_EMAIL_MESSAGES,
        payload: {
            url: '/site/messages/email/update/',
            data: data
        }
    };
};

export const editSitePushMessages = (data) => {
    return {
        type: CONSTANTS.EDIT_SITE_PUSH_MESSAGES,
        payload: {
            url: '/site/messages/push/update/',
            data: data
        }
    };
};

export const editNotificationMessages = ({ messages = [], language = '' }, companyUUID) => {
    return {
        type: CONSTANTS.EDIT_NOTIFICATION_MESSAGES,
        payload: {
            url: '/company/messages/notifications/update/',
            data: {
                companyUUID: companyUUID,
                language: language,
                notificationsMessages: messages
            }
        }
    };
};

export const getSiteMessageVariables = () => {
    return {
        type: CONSTANTS.GET_SITE_MESSAGE_VARIABLES,
        payload: {
            url: '/notifications/data/read/',
            data: {}
        }
    };
};

export const searchFob = (data) => {
    return {
        type: CONSTANTS.SEARCH_FOB,
        payload: {
            url: '/fob/lookup/',
            data: {
                mac: data
            }
        }
    };
};

export const setFobDetails = (data) => {
    return {
        type: CONSTANTS.SET_FOB_DETAILS,
        payload: data
    };
};

export const fetchTrainingList = () => {
    return {
        type: CONSTANTS.FETCH_TRAINING_LIST,
        payload: {
            url: '/site/digital/ack/read/',
            data: {}
        }
    };
};

export const setTrainingList = (data) => {
    return {
        type: CONSTANTS.SET_TRAINING_LIST,
        payload: data
    };
};

export const emailDailyActivity = () => {
    return {
        type: CONSTANTS.EMAIL_DAILY_ACTIVITY,
        payload: {
            url: '/sitedata/export/',
            data: {}
        }
    };
};

export const fetchLoginTypes = (data) => {
    return {
        type: CONSTANTS.FETCH_LOGIN_TYPES,
        payload: {
            url: '/user/logintype/',
            data
        }
    };
};

export const fetchEntryMessages = () => {
    return {
        type: CONSTANTS.FETCH_ENTRY_MESSAGES,
        payload: {
            url: '/entrymessage/read/',
            data: {}
        }
    };
};

export const setLoginTypes = (data) => {
    return {
        type: CONSTANTS.SET_LOGIN_TYPES,
        payload: data
    };
};

export const setEntryMessages = (data) => {
    return {
        type: CONSTANTS.SET_ENTRY_MESSAGES,
        payload: data
    };
};

export const createEntryMessage = ({
    messageTitle = '',
    messageBody = '',
    recipientType = '',
    recipientUUIDs = [],
    startTime = [],
    endTime = [],
    messageFrequency = '',
    entryUUID = ''
}) => {
    return {
        type: CONSTANTS.CREATE_ENTRY_MESSAGE,
        payload: {
            url: '/entrymessage/create/',
            data: {
                messageTitle,
                messageBody,
                recipientType,
                recipientUUIDs,
                startTime,
                endTime,
                messageFrequency,
                entryUUID
            }
        }
    };
};

export const editEntryMessage = (data) => {
    return {
        type: CONSTANTS.EDIT_ENTRY_MESSAGE,
        payload: {
            url: '/entrymessage/update/',
            data
        }
    };
};

export const deleteEntryMessage = (uuid) => {
    return {
        type: CONSTANTS.DELETE_ENTRY_MESSAGE,
        payload: {
            url: '/entrymessage/delete/',
            data: {
                uuid
            }
        }
    };
};

export const requestAudit = () => {
    return {
        type: CONSTANTS.REQUEST_AUDIT,
        payload: {
            url: '/audit/request/',
            data: {}
        }
    };
};

export const setAudit = (data) => {
    return {
        type: CONSTANTS.SET_AUDIT,
        payload: data
    };
};

export const requestHistoricalAudits = () => {
    return {
        type: CONSTANTS.REQUEST_HISTORICAL_AUDITS,
        payload: {
            url: '/audit/read/all/',
            data: {
                siteUUID: JSON.parse(localStorage.getItem('nse_login_data')).siteUUID
            }
        }
    };
};

export const setHistoricalAudits = (data) => {
    return {
        type: CONSTANTS.SET_HISTORICAL_AUDITS,
        payload: data
    };
};

export const requestIndividualAudit = (auditUUID) => {
    return {
        type: CONSTANTS.REQUEST_INDIVIDUAL_AUDIT,
        payload: {
            url: '/audit/read/',
            data: {
                auditUUID,
                siteUUID: JSON.parse(localStorage.getItem('nse_login_data')).siteUUID
            }
        }
    };
};

export const setIndividualAudit = (data) => {
    return {
        type: CONSTANTS.SET_INDIVIDUAL_AUDIT,
        payload: data
    };
};

export const fetchHubspotTickets = () => {
    return {
        type: CONSTANTS.FETCH_HUBSPOT_TICKETS,
        payload: {
            url: '/hubspot/tickets/read/',
            data: {
                properties: [
                    'subject',
                    'created_by',
                    'createdate',
                    'time_to_close',
                    'content',
                    'status',
                    'hs_pipeline',
                    'hs_pipeline_stage',
                    'hs_lastmodifieddate',
                    'hs_ticket_priority',
                    'site_id'
                ]
            }
        }
    };
};

export const setHubspotTickets = (data) => {
    return {
        type: CONSTANTS.SET_HUBSPOT_TICKETS,
        payload: data
    };
};

export const fetchHubspotTicket = (data) => {
    return {
        type: CONSTANTS.FETCH_HUBSPOT_TICKET,
        payload: {
            url: '/hubspot/ticket/read/',
            data: {
                id: data,
                properties: [
                    'subject',
                    'created_by',
                    'createdate',
                    'time_to_close',
                    'content',
                    'status',
                    'hs_pipeline',
                    'hs_pipeline_stage',
                    'hs_lastmodifieddate',
                    'hs_ticket_priority',
                    'hs_created_by_user_id',
                    'source_type',
                    'site_id'
                ]
            }
        }
    };
};

export const setHubspotTicket = (data) => {
    return {
        type: CONSTANTS.SET_HUBSPOT_TICKET,
        payload: data
    };
};

export const fetchHubspotCompanies = () => {
    return {
        type: CONSTANTS.FETCH_HUBSPOT_COMPANIES,
        payload: {
            url: '/hubspot/companies/read/',
            data: {}
        }
    };
};

export const submitHubspotFeedback = () => {
    return {
        type: CONSTANTS.SUBMIT_HUBSPOT_FEEDBACK,
        payload: {
            url: '/hubspot/submit/feedback/',
            data: {}
        }
    };
};

export const setHubspotCompanies = (data) => {
    return {
        type: CONSTANTS.SET_HUBSPOT_COMPANIES,
        payload: data
    };
};

export const requestEcobeePin = (fakeData = false) => {
    return {
        type: CONSTANTS.REQUEST_ECOBEE_PIN,
        payload: {
            url: '/ecobee/request/pin/',
            data: {},
            fakeData
        }
    };
};

export const fetchEcobeePin = (fakeData = false) => {
    return {
        type: CONSTANTS.FETCH_ECOBEE_PIN,
        payload: {
            url: '/ecobee/view/pin/',
            data: {},
            fakeData
        }
    };
};

export const setEcobeePin = (data) => {
    return {
        type: CONSTANTS.SET_ECOBEE_PIN,
        payload: data
    };
};

export const fetchThermostat = (fakeData = false) => {
    return {
        type: CONSTANTS.FETCH_THERMOSTAT,
        payload: {
            url: '/ecobee/thermostats/view/',
            data: {
                selection: {
                    selectionType: 'registered',
                    selectionMatch: '',
                    includeRuntime: true,
                    includeSettings: true
                }
            },
            fakeData
        }
    };
};

export const setThermostat = (data) => {
    return {
        type: CONSTANTS.SET_THERMOSTAT,
        payload: data
    };
};

export const requestEcobeeToken = (fakeData = false) => {
    return {
        type: CONSTANTS.REQUEST_ECOBEE_TOKEN,
        payload: {
            url: '/ecobee/request/token/',
            data: {},
            fakeData
        }
    };
};

export const updateThermostatTemp = (temp, fakeData = false) => {
    return {
        type: CONSTANTS.UPDATE_THERMOSTAT_TEMP,
        payload: {
            url: '/ecobee/thermostat/update/',
            data: {
                selection: {
                    selectionType: 'registered',
                    selectionMatch: ''
                },
                functions: [
                    {
                        type: 'setHold',
                        params: {
                            holdType: 'indefinite',
                            heatHoldTemp: temp,
                            coolHoldTemp: temp
                        }
                    }
                ]
            },
            temp,
            fakeData
        }
    };
};

export const fetchSiteStats = () => {
    return {
        type: CONSTANTS.FETCH_SITE_STATS,
        payload: {
            url: '/site/stats/totals/',
            data: {}
        }
    };
};

export const setSiteStats = (data) => {
    return {
        type: CONSTANTS.SET_SITE_STATS,
        payload: data
    };
};

export const fetchHubspotFields = () => {
    return {
        type: CONSTANTS.FETCH_HUBSPOT_FIELDS,
        payload: {
            url: '/hubspot/form/fields/',
            data: {}
        }
    };
};

export const setHubspotFields = (data) => {
    return {
        type: CONSTANTS.SET_HUBSPOT_FIELDS,
        payload: data
    };
};

export const submitCustomHubspotForm = (data) => {
    return {
        type: CONSTANTS.SUBMIT_CUSTOM_HUBSPOT_FORM,
        payload: {
            url: '/hubspot/form/submit/',
            data
        }
    };
};

export const addCompanyLanguage = (language = '') => {
    return {
        type: CONSTANTS.ADD_COMPANY_LANGUAGE,
        payload: {
            url: '/company/messages/languages/new/',
            data: {
                language
            }
        }
    };
};

export const setTrainingHeaders = (data) => {
    return {
        type: CONSTANTS.SET_TRAINING_HEADERS,
        payload: data
    };
};

export const setSiteCreateData = (data) => {
    return {
        type: CONSTANTS.SET_SITE_CREATE_DATA,
        payload: data,
    }
};

export const setCompanyCreateData = (data) => {
    return {
        type: CONSTANTS.SET_COMPANY_CREATE_DATA,
        payload: data,
    }
};

export const fetchScreenImages = (data) => {
    return {
        type: CONSTANTS.FETCH_SCREEN_IMAGES,
        payload: {
            url: '/screen/image/read/',
            data
        }
    };
};

export const uploadScreenImage = ({id = null, screenType = null, language = 'en', image = null}) => {
    return {
        type: CONSTANTS.UPLOAD_SCREEN_IMAGE,
        payload: {
            url: '/screen/image/upload/',
            data: {
                id, // int
                screenType, // string (input, invalid, outofschedule, overlocked, splash, unlocked)
                language, // string (en is default)
                image // file
            }
        }
    };
};

export const setScreenImages = (data) => {
    return {
        type: CONSTANTS.SET_SCREEN_IMAGES,
        payload: data,
    }
};

export const setFeatureFlagHeaders = (data) => {
    return {
        type: CONSTANTS.SET_FEATURE_FLAG_HEADERS,
        payload: data
    };
};

export const blacklistAllFobs = () => {
    return {
        type: CONSTANTS.BLACKLIST_ALL_FOBS,
        payload: {
            url: '/fob/blacklistallfobs/',
            data: {
            }
        }
    };
};

export const fetchDigitalAuditsList = () => {
    return {
        type: CONSTANTS.FETCH_DIGITAL_AUDITS_LIST,
        payload: {
            url: '/digitalAudit/list/',
            data: {}
        }
    };
};

export const setDigitalAuditsList = (data) => {
    return {
        type: CONSTANTS.SET_DIGITAL_AUDITS_LIST,
        payload: data
    };
};

export const fetchDigitalAuditsFacilities = (siteIds) => {
    return {
        type: CONSTANTS.FETCH_DIGITAL_AUDITS_FACILITIES,
        payload: {
            url: '/digitalAudit/facilities/list/',
            data: {
                siteIds
            }
        }
    };
};

export const setDigitalAuditsFacilities = (data) => {
    return {
        type: CONSTANTS.SET_DIGITAL_AUDITS_FACILITIES,
        payload: data
    };
};

export const setDigitalAuditsFacilitiesStats = (data) => {
    return {
        type: CONSTANTS.SET_DIGITAL_AUDITS_FACILITIES_STATS,
        payload: data
    };
};

export const setDigitalAuditsFacilitiesCompanies = (data) => {
    return {
        type: CONSTANTS.SET_DIGITAL_AUDITS_FACILITIES_COMPANIES,
        payload: data
    };
};

export const fetchDigitalAuditSummary = (auditId) => {
    return {
        type: CONSTANTS.FETCH_DIGITAL_AUDIT_SUMMARY,
        payload: {
            url: '/digitalAudit/readSummary/',
            data: {
                auditId
            }
        }
    };
};

export const setDigitalAuditSummary = (data) => {
    return {
        type: CONSTANTS.SET_DIGITAL_AUDIT_SUMMARY,
        payload: data
    };
};

export const fetchDigitalAuditHistory = (auditId) => {
    return {
        type: CONSTANTS.FETCH_DIGITAL_AUDIT_HISTORY,
        payload: {
            url: '/digitalAudit/lifecycleList/',
            data: {
                auditId
            }
        }
    };
};

export const setDigitalAuditHistory = (data) => {
    return {
        type: CONSTANTS.SET_DIGITAL_AUDIT_HISTORY,
        payload: data
    };
};

export const fetchDigitalAuditDetail = (auditId) => {
    return {
        type: CONSTANTS.FETCH_DIGITAL_AUDIT_DETAIL,
        payload: {
            url: '/digitalAudit/readDetail/',
            data: {
                auditId
            }
        }
    };
};

export const setDigitalAuditDetail = (data) => {
    return {
        type: CONSTANTS.SET_DIGITAL_AUDIT_DETAIL,
        payload: data
    };
};

export const requestNewDigitalAudit = () => {
    return {
        type: CONSTANTS.REQUEST_NEW_DIGITAL_AUDIT,
        payload: {
            url: '/digitalAudit/create/',
            data: {}
        }
    };
};

export const requestBulkDigitalAudit = (data) => {
    return {
        type: CONSTANTS.REQUEST_BULK_DIGITAL_AUDIT,
        payload: {
            url: '/digitalAudit/create/bulk/',
            data
        }
    };
};

export const requestDigitalAuditSummaryExport = (data) => {
    return {
        type: CONSTANTS.REQUEST_DIGITAL_AUDIT_SUMMARY_EXPORT,
        payload: {
            url: '/digitalAudit/summary/export/',
            data
        }
    };
};

export const updateAuditLifecycle = (data, requestNew = false) => {
    return {
        type: CONSTANTS.UPDATE_AUDIT_LIFECYCLE,
        payload: {
            url: '/digitalAudit/lifecycleChange/',
            data,
            requestNew
        }
    };
};