import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { fadeIn } from 'react-animations';
import moment from 'moment-timezone';

import { UnitIconOpen } from 'utilities/icons';
import Spinner from 'components/Spinner';
import Header from 'components/Header/dashboard';
class DashboardOpenUnits extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let { intl, history, theme, width, openUnits, authUser, site, userTimezone, editWidgets } = this.props;
        this.styles = styles(theme);
        let now = authUser && authUser.useSiteTime && site ? moment().tz(site.timeZone) : moment().tz(userTimezone);
        const permissions = authUser.permissions;
        return (
            <>
                <Header
                    Icon={UnitIconOpen}
                    iconSize={26}
                    title={'pages.dashboard.openUnits'}
                    subtitle={openUnits && openUnits.length}
                />
                <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                    <Col
                        className={
                            permissions.includes('manage_access_codes') && site && site.has3K
                                ? css(this.styles.shortWrapper)
                                : permissions.includes('remote_open')
                                ? css(this.styles.wrapper)
                                : css(this.styles.tallWrapper)
                        }
                        style={{ padding: '0 0', overflowY: 'auto' }}
                        xs={12}>
                        {openUnits ? (
                            <table className={css(this.styles.table)}>
                                {openUnits && openUnits.length > 0 ? (
                                    <tbody>
                                        {openUnits.map((unit) => (
                                            <tr
                                                key={'UNITLIST' + unit.id}
                                                className={css(this.styles.row)}
                                                onClick={() => history.push(`/app/units/display/${unit.id}`)}>
                                                <td style={{ paddingTop: 6, width: 28 }}>
                                                    <UnitIconOpen
                                                        height={22}
                                                        width={22}
                                                        color={theme.color.themeColor}
                                                    />
                                                </td>
                                                <td
                                                    className={
                                                        width > 400 ? css(this.styles.bold) : css(this.styles.text)
                                                    }>
                                                    {unit.name && unit.name}
                                                </td>
                                                {width > 400 && (
                                                    <td style={{ marginLeft: 10 }} className={css(this.styles.text)}>
                                                        {unit.lockState && unit.lockState == 'JAMMED_LOCKING'  
                                                        ? intl.formatMessage({
                                                            id: 'db.jammed'
                                                        })
                                                        :unit.lockState == 'JAMMED_UNLOCKING' 
                                                        ? intl.formatMessage({
                                                            id: 'db.jammed'
                                                        })
                                                        :intl.formatMessage({ id: 'db.opened' }) }{' '}
                                                        {unit.locks[0].stateUpdated
                                                            ? moment
                                                                  .duration(now.diff(unit.locks[0].stateUpdated))
                                                                  .humanize()
                                                            : moment
                                                                  .duration(now.diff(unit.locks[0].time))
                                                                  .humanize()}{' '}
                                                        {intl.formatMessage({ id: 'form.ago' })}
                                                    </td>
                                                )}
                                                {width > 400 && !editWidgets && (
                                                    <td className={css(this.styles.text)}>
                                                        {unit.rentalState && unit.rentalState == 'inuse'
                                                            ? intl.formatMessage({
                                                                  id: 'filters.units.occupied'
                                                              })
                                                            : unit.rentalState == 'available'
                                                            ? intl.formatMessage({
                                                                  id: 'filters.units.available'
                                                              })
                                                            : unit.rentalState == 'repo'
                                                            ? intl.formatMessage({
                                                                  id: 'filters.units.repo'
                                                              })
                                                            : unit.rentalState == 'overlock'
                                                            ? intl.formatMessage({
                                                                  id: 'filters.units.overlock'
                                                              })
                                                            : intl.formatMessage({
                                                                  id: 'filters.units.other'
                                                              })}
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody
                                        style={{
                                            minHeight: 102,
                                            fontSize: 12,
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            animationName: fadeIn,
                                            animationDuration: '1.2s'
                                        }}>
                                        <tr>
                                            <td>
                                                {intl.formatMessage({
                                                    id: 'comp.table.cell.empty'
                                                })}
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        ) : (
                            <Spinner />
                        )}
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, auth, units }) => {
    const { theme, width, userTimezone } = app;
    const { authUser, site } = auth;
    const { openUnits } = units;
    return { theme, authUser, site, openUnits, width, userTimezone };
};

export default withRouter(injectIntl(connect(mapStateToProps)(DashboardOpenUnits)));

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            maxHeight: 182,
            height: 182
        },
        tallWrapper: {
            maxHeight: 290,
            height: 290
        },
        shortWrapper: {
            maxHeight: 109,
            height: 109
        },
        header: {
            color: theme.color.themeColor,
            margin: '0 0 15px 0'
        },
        headerEdit: {
            color: theme.color.themeColor,
            marginBottom: 10,
            fontSize: 20
        },
        table: {
            width: '100%',
            fontSize: 11
        },
        row: {
            width: '100%',
            borderBottom: '1px solid' + theme.color.borderColor,
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        bold: {
            fontWeight: 600,
            marginLeft: 5,
            paddingTop: 4
        },
        text: {
            textTransform: 'capitalize',
            paddingTop: 4
        }
    });
