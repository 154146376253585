import * as CONSTANTS from 'constants';
import moment from 'moment';

const INIT_STATE = {
    siteUserList: null,
    removedUsers: null,
    companyUserListStats: null,
    siteUserListStats: null,
    siteUserListStatusStats: null,
    siteUserListServiceStats: null,
    userObject: null,
    userPhoneDetails: null,
    userFobs: [],
    userShares: [],
    userPin: null,
    activeUser: 'info',
    loginAttempts: null,
    userUnits: null,
    userKeypadStatus: false,
    userFilterStatus: 'all',
    userFilterService: 'all',
    userFilterSchedule: 'all',
    userEditedSchedule: null,
    supportUsers: [],
    onsiteUsers: null,
    employeesOnline: null,
    onsiteUsersAll: null,
    managerSites: null,
    loiteringUsers: [],
    transferringUsers: null,
    userRoleActiveFilter: null,
    tenantUnits: null,
    tenantShares: null,
    currentUserFilter: true,
    usersHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 200,
            icon: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'form.appStatus',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 140,
            sortTitle: 'state',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.phone',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'phone',
            width: 120,
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.email',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'email',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'pages.users.schedule.type',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'schedule',
            laptop: true,
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            title: 'comp.table.header.unitsSinglePlural',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'units',
            laptop: true,
            style: { textAlign: 'left' }
        },
        {
            id: 6,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 50,
        }
    ],
    usersScheduleHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 200,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.phone',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'phone'
        },
        {
            id: 2,
            title: 'comp.table.header.email',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'email'
        },
        {
            id: 3,
            title: 'comp.table.header.status',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'state'
        },
        {
            id: 4,
            title: 'pages.users.schedule.type',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'schedule'
        }
    ],
    usersHeaderOld: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 200,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.phone',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'phone'
        },
        {
            id: 2,
            title: 'comp.table.header.email',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'email'
        },
        {
            id: 3,
            title: 'comp.table.header.status',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'state'
        }
    ],
    usersHeaderLarge: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 200,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'form.appStatus',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 140,
            sortTitle: 'state',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.phone',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'phone'
        },
        {
            id: 3,
            title: 'comp.table.header.email',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'email'
        },
        {
            id: 4,
            title: 'pages.users.schedule.type',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'schedule'
        },
        {
            id: 5,
            title: 'comp.table.header.status',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'state'
        },
        {
            id: 6,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 170
        },
        {
            id: 7,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 120
        },
        {
            id: 8,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 80
        }
    ],
    removedUsersHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'forUserFirstName',
            mobile: true,
            width: 310,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'pages.dashboard.removedReason',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'reason',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'pages.dashboard.removedBy',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'byUserFirstName',
            style: { textAlign: 'left' }
        }
    ],
    userPermissionHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 200,
            style: { textAlign: 'left' }
        }
    ],
    userSiteHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 145,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.city',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'city',
            style: { textAlign: 'right' }
        }
    ],
    onsiteUsersHeader: [
        {
            id: 0,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 55
        },
        {
            id: 1,
            title: 'comp.table.header.name',
            active: false,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 145,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.date.update',
            active: true,
            sortable: true,
            order: false,
            mobile: false,
            sortTitle: 'onSiteUpdated',
            style: { textAlign: 'right' }
        },
        {
            id: 3,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 70
        }
    ],
    fobHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 145,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.mac',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'mac'
        }
    ],
    sharedHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.phone',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'phone'
        },
        {
            id: 2,
            title: 'comp.table.header.email',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'email'
        },
        {
            id: 3,
            title: 'comp.table.header.status',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'state'
        }
    ],
    usersFilters: [],
    usersFiltersOld: [
        {
            title: 'filters.all',
            value: ['client', 'employee', 'technician', 'site_manager', 'company_manager'],
            active: true
        },
        {
            title: 'filters.users.client',
            value: ['client'],
            active: false
        },
        {
            title: 'filters.users.employee',
            value: ['employee'],
            active: false
        },
        {
            title: 'filters.users.technician',
            value: ['technician'],
            active: false
        },
        {
            title: 'filters.users.site_manager',
            value: ['site_manager'],
            active: false
        },
        {
            title: 'filters.users.company_manager',
            value: ['company_manager'],
            active: false
        }
    ],
    removedUsersDates: {
        begin: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD')
    },
    transferHeaders: [
        {
            id: 0,
            title: 'comp.table.header.user',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'userFirstName',
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.button.transfer.expiration',
            width: 134,
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'transferExpirationTime'
        }
    ],
    usersSimpleHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 200,
            icon: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'form.appStatus',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 140,
            sortTitle: 'state',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.phone',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            laptop: true,
            sortTitle: 'phone',
            width: 120,
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.email',
            active: false,
            sortable: true,
            order: true,
            laptop: true,
            mobile: false,
            sortTitle: 'email',
            style: { textAlign: 'left' }
        },
        {
            id: 6,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 50
        }
    ],
    userSharedUnitsHeaders: [
        {
            id: 0,
            title: 'form.unit',
            active: false,
            sortable: false,
            order: true,
            mobile: true,
            sortTitle: '',
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.user',
            active: false,
            sortable: false,
            order: true,
            mobile: true,
            sortTitle: '',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'db.share_user',
            active: false,
            sortable: false,
            order: true,
            mobile: true,
            sortTitle: '',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'pages.users.pin.expire',
            width: 134,
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            style: { textAlign: 'right' },
            sortTitle: 'endDate'
        }
    ],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_SITE_USER_LIST: {
            return {
                ...state,
                siteUserList: action.payload
            };
        }
        case CONSTANTS.SET_REMOVED_USERS: {
            return {
                ...state,
                removedUsers: action.payload
            };
        }
        case CONSTANTS.SET_COMPANY_USER_LIST_STATS: {
            return {
                ...state,
                companyUserListStats: action.payload
            };
        }
        case CONSTANTS.SET_SITE_USER_LIST_STATS: {
            return {
                ...state,
                siteUserListStats: action.payload
            };
        }
        case CONSTANTS.SET_SITE_USER_LIST_STATUS_STATS: {
            return {
                ...state,
                siteUserListStatusStats: action.payload
            };
        }
        case CONSTANTS.SET_SITE_USER_LIST_SERVICE_STATS: {
            return {
                ...state,
                siteUserListServiceStats: action.payload
            };
        }
        case CONSTANTS.SET_USER_FOBS: {
            return {
                ...state,
                userFobs: action.payload
            };
        }
        case CONSTANTS.SET_USER_SHARES: {
            return {
                ...state,
                userShares: action.payload
            };
        }
        case CONSTANTS.SET_USER_SHARED_UNITS_HEADERS: {
            return {
                ...state,
                userSharedUnitsHeaders: action.payload
            };
        }
        case CONSTANTS.SET_USER_DETAILS: {
            return {
                ...state,
                userObject: action.payload
            };
        }
        case CONSTANTS.SET_USER_PHONE_DETAILS: {
            return {
                ...state,
                userPhoneDetails: action.payload
            };
        }
        case CONSTANTS.SET_USER_PIN: {
            return {
                ...state,
                userPin: action.payload
            };
        }
        case CONSTANTS.SET_USER_LOGIN_ATTEMPTS: {
            return {
                ...state,
                loginAttempts: action.payload
            };
        }
        case CONSTANTS.SET_USER_HEADERS: {
            return {
                ...state,
                usersHeader: action.payload
            };
        }
        case CONSTANTS.SET_USER_HEADERS_OLD: {
            return {
                ...state,
                usersHeaderOld: action.payload
            };
        }
        case CONSTANTS.SET_USER_PERMISSION_HEADERS: {
            return {
                ...state,
                userPermissionHeader: action.payload
            };
        }
        case CONSTANTS.SET_USER_SHARED_HEADERS: {
            return {
                ...state,
                sharedHeader: action.payload
            };
        }
        case CONSTANTS.SET_USER_FOB_HEADERS: {
            return {
                ...state,
                fobHeader: action.payload
            };
        }
        case CONSTANTS.SET_USER_FILTERS: {
            return {
                ...state,
                usersFilters: action.payload
            };
        }
        case CONSTANTS.SET_USER_FILTERS_OLD: {
            return {
                ...state,
                usersFiltersOld: action.payload
            };
        }
        case CONSTANTS.SET_USER_ACTIVE: {
            return {
                ...state,
                activeUser: action.payload
            };
        }
        case CONSTANTS.SET_USER_UNITS: {
            return {
                ...state,
                userUnits: action.payload
            };
        }
        case CONSTANTS.SET_USER_KEYPAD_STATUS: {
            return {
                ...state,
                userKeypadStatus: action.payload
            };
        }
        case CONSTANTS.SET_USER_FILTER_STATUS: {
            return {
                ...state,
                userFilterStatus: action.payload
            };
        }
        case CONSTANTS.SET_USER_FILTER_SERVICE: {
            return {
                ...state,
                userFilterService: action.payload
            };
        }
        case CONSTANTS.SET_USER_FILTER_SCHEDULE: {
            return {
                ...state,
                userFilterSchedule: action.payload
            };
        }
        case CONSTANTS.SET_USER_EDITED_SCHEDULE: {
            return {
                ...state,
                userEditedSchedule: action.payload
            };
        }
        case CONSTANTS.SET_SUPPORT_USERS: {
            return {
                ...state,
                supportUsers: action.payload
            };
        }
        case CONSTANTS.SET_ONSITE_USERS: {
            return {
                ...state,
                onsiteUsers: action.payload
            };
        }
        case CONSTANTS.SET_EMPLOYEES_ONLINE: {
            return {
                ...state,
                employeesOnline: action.payload
            };
        }
        case CONSTANTS.SET_ONSITE_USERS_ALL: {
            return {
                ...state,
                onsiteUsersAll: action.payload
            };
        }
        case CONSTANTS.SET_MANAGER_SITES: {
            return {
                ...state,
                managerSites: action.payload
            };
        }
        case CONSTANTS.SET_REMOVED_USERS_DATES: {
            return {
                ...state,
                removedUsersDates: action.payload
            };
        }
        case CONSTANTS.SET_LOITERING_USERS: {
            return {
                ...state,
                loiteringUsers: action.payload
            };
        }
        case CONSTANTS.SET_TRANSFERRING_USERS: {
            return {
                ...state,
                transferringUsers: action.payload
            };
        }
        case CONSTANTS.SET_USER_ROLE_ACTIVE_FILTER: {
            return {
                ...state,
                userRoleActiveFilter: action.payload
            };
        }
        case CONSTANTS.SET_TENANT_UNITS: {
            return {
                ...state,
                tenantUnits: action.payload
            };
        }
        case CONSTANTS.SET_TENANT_SHARES: {
            return {
                ...state,
                tenantShares: action.payload
            };
        }
        case CONSTANTS.SET_CURRENT_USER_FILTER: {
            return {
                ...state,
                currentUserFilter: action.payload
            };
        }
        case CONSTANTS.SET_TRANSFER_HEADERS: {
            return {
                ...state,
                transferHeaders: action.payload
            };
        }
        default:
            return state;
    }
};
